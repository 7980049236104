<template>
<div class="cell small-10">

    <!-- <div class="grid-container"> -->
        <div class="grid-y medium-grid-frame text-center" style="min-height:600px">
            <div class="cell small-1"></div>
            <h1 class="cell qmajorheader" v-html="langConv('Login')"></h1>
            <h2 class="cell qsubheader" v-html="langConv('Login to retrieve your site settings.So you can set up the site how you want: your bookmakers, your default exchange charges, you favourites, your odds format and more') "></h2>
       
            <div class="cell" ><a href="https://www.cookiepolicygenerator.com/live.php?token=2zusJbypmfgHkk7zrQ89iSsJBPESX9vq" style="color:darkslategray;font-size:1rem" v-html="langConv('Please note that no data is shared with 3rd parties')"></a></div>

            <div class="cell small-1">
                
                <div class="grid-x text-center" style="padding-top:100px">
                    <div class="cell auto"></div>
                        <div class="cell shrink"> 
                            <!-- <div class="fb-login-button" data-width="" data-size="medium" data-button-type="login_with" data-layout="default" data-auto-logout-link="false" data-use-continue-as="false" data-onlogin="getFaceBookDetails"></div> -->
                            <div style="height:39px;padding-top:7px;width:190px;border: 1px solid lightgrey;font-size:14px; font-weight:normal; border-radius: 19px;" @click="loginFaceBook"><img :src="require('/public/assets/images/fb_icon.png')" style="height:24px;width:24px" alt=""  /><span style="padding-left:10px" v-html="langConv('Sign in with Facebook')"></span></div>
                    </div>
                    <div class="cell auto"></div>
                </div>

            </div>

            <div class="cell small-1">
                
                <div class="grid-x text-center" style="padding-top:20px">
                    <div class="cell auto"></div>
                        <div class="cell shrink" >     
                            <div id="g_id_onload"
                            data-client_id="1066089732004-ia7ai100u65hu8v56697uc57ojp7nios.apps.googleusercontent.com"
                            data-context="use"
                            data-ux_mode="popup"
                            data-callback="responseGoogle"
                            data-auto_prompt="false"></div>



                            <div class="g_id_signin"
                            data-type="standard"
                            data-shape="pill"
                            data-theme="outline"
                            data-text="sign_in_with"
                            data-size="large"
                            data-logo_alignment="left"
                            data-width="190"></div>
                        </div>
                    <div class="cell auto"></div>    
                </div>
            </div>
        </div>
        
</div>




</template>

<script>

import jwt_decode from "jwt-decode";

    export default {
        name: "login",
        // data: function () {
        //     return {

        //         facebookButton: true,

        //     }
        // },

        created() {



            this.loadGoogleClient();
           this.loadFaceBookClient();

           window.responseGoogle = this.responseGoogle;


        },


        methods: {



          responseGoogle: function(token) {

                let profile = jwt_decode(token.credential);

                //if no email then error TODO

                //if any name is undefined use the part of the email
                if (profile.name == undefined) profile.name == profile.email.substr(0, profile.email.indexOf('@'));

                this.$store.commit('setName', profile.name)
                this.$store.commit('setEmail', profile.email)

                this.$store.commit('setAuthtype', 'Google')
                this.$store.commit('setSignedin', true)

                var self = this;

                this.getUsers();

                //retrieve user credentials (accept the default is this is first time)
                this.retrieveuser().then((response) => {

                    //console.log(response);
                        //window.location.href = "/";
                        self.$router.push("/");

                    });

          },

          loadFaceBookClient: function() {

            var _self = this;

             return new Promise(resolve => {
                // wait for facebook sdk to initialize before starting the vue app
                window.fbAsyncInit = function () {
                    FB.init({
                        appId: '1587075881723039',
                        cookie: true,
                        xfbml: true,
                        version: 'v15.0'
                    });

                    //dont need to check login status because will not be calling twice - 17/11/22

                    FB.getLoginStatus(function(response) {

                        if (response.status=='connected') {
                            //then logout
                           FB.logout(this);

                        }
                    });
                };

                // load facebook sdk script
                (function (d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0];
                     if (d.getElementById(id)) { return; }
                     js = d.createElement(s); js.id = id;
                     js.src = "https://connect.facebook.net/en_US/sdk.js";
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));
                });
          },

          loginFaceBook: function() {

            var self = this;

            FB.login(function (response) {
                if (response.authResponse) {
                    // Get and display the user profile data
                    self.getFaceBookDetails();
                } else {
                    console.log('User cancelled login or did not fully authorize.');
                }
                }, {scope: 'email'});
          },

          getFaceBookDetails: function() {

            var self = this;

                  FB.api('/me', {locale: 'en_US', fields: 'id,first_name,last_name,email,link,gender,locale'},
                  function (response) {

                    if (!response || response.error) {
                        alert('A problem occurred retrieving information from facebook');
                    } else {
                        console.log(response.email)

               // if (profile.name == undefined) profile.name == profile.email.substr(0, profile.email.indexOf('@'));


                var username = response.first_name + ' ' + response.last_name.charAt(0)



                self.$store.commit('setName', username)
                self.$store.commit('setEmail', response.email)

                self.$store.commit('setAuthtype', 'Facebook')
                self.$store.commit('setSignedin', true)


                self.getUsers();

                //retrieve user credentials (accept the default is this is first time)
                self.retrieveuser().then((response) => {

                   // console.log(response);

                        self.$router.push("/");
                    });

                        //  console.log('<b>FB ID:</b> '+response.id+'</p><p><b>Name:</b> '+response.first_name+' '+response.last_name+'</p><p><b>Email:</b> '+response.email+'</p><p><b>Gender:</b> '+response.gender+'</p><p><b>FB Profile:</b> <a target="_blank" href="'+response.link+'">click to view profile</a></p>')
          }} );

            } ,










    }
}




</script>

<style>

a:hover {
    color: grey;
}

</style>