<!-- needs to pass the document name. Country name can be got from  -->
<template>
    <div v-html="document" v-if="documentloaded">

    </div>
</template>

<script>
    export default {
        name: "languagevarianthtml",
        data() {
            return {
                document: '',
                documentloaded: false
            }
        },
        props: {
            filename: {
                type: String,
                default: 'aboutthissite' 
            },
         
        },
        mounted () {

            var doctype = '.html' //may need to be a prop in future
            
            //what country
            let country = this.$store.state.countryname;

            country = this.backtoEnglish(country);

            //load file using localjson code
            this.localJSONLoad(process.env.VUE_APP_HTML + this.filename + ' - ' + country + doctype).then(response => {
                this.document = response;
                this.documentloaded = true;

             }).catch(err => {

                console.log("HTML Document Load error -  " + err)
      
            });
            
        },
    }
</script>

<style scoped>

</style>