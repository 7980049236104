

<template>
    <div>
        <div style="height: 300px;width: 100%;position: relative;text-align: center;">
                <div>{{message}}</div>
                <div  class="content" v-html="langConv('There is no data that matches your selection(s)')"></div>
         </div>    
    </div>

</template>

<script>
    export default {

        name: "nodata",

       
        props: {
            message: { type: Number, Default: 0 }
        }

    }
</script>
