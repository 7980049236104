<template >
   <div style="height:100%">
    <div  v-if="ttype==0" style="height:100%;background-color: #E6E7E9">
    <!-- vertical sports menu -->
        <ul :class="clsname">
  
            <li v-for="(row,i) in $store.state.sports" :key="i">  
                <router-link  :to="{name:'sport', params: {sport:row.D, opencountry: 'All' }}" style="background-color:lightgrey;margin:5px 5px 0px 5px; border-radius: 5px;line-height:22px; border:1px solid #AEAEAE">
                    
                        <div  :class="row.Description" ></div>
                        <div class="qsmallfontplus1 qborderlink" style="min-width:80px;text-align:center" v-html="langConv(row.Friendly)" ></div>
                   
                </router-link>
            </li>

    </ul>
    </div>
    <!-- horizontal sports menu items for small screens -->
    <div v-if="ttype==1" style="background-color: #E6E7E9" >
   
        <carousel  id="sportscarousel" :navigationEnabled="false" :paginationEnabled="false" :perPageCustom="[[400,2.7],[500,3.7],[600,4.7],[700,5.7],[800,6.7],[900,7.7],[1000,8.7],[1100,9.7]]" :scrollPerPage="true" v-if="$store.state.sports.length > 0">
          
            <slide v-for="(row,i) in $store.state.sports" :key="i" style="height:42px;" >
                
                <router-link :to="{name:'sport', params: {sport:row.D, opencountry: 'All' }}">
               
                    <div class="grid-x text-center" style="background-color: lightgrey; border-radius: 5px;padding:5px 2px 5px 2px;line-height:22px;border:1px solid #AEAEAE" >
                        <div class="cell small-2" :class="row.Description"></div>
                        <div class="cell auto qsmallfontplus1 qbordertext" v-html="langConv(row.Friendly)"></div>                   
                    </div>
                    
                </router-link>
              
            </slide>
            
        </carousel>
    </div>
    
    <!-- horizontal menu for most popular competitions -->
    <div class="grid-x" v-else-if="ttype==2" >
        <div class="cell shrink"></div>

        <div class="cell auto" style="margin-left:25px" >
        <carousel style="margin-top:4px;"  :navigationEnabled="true" :paginationEnabled="false" :perPageCustom="[[400,4],[486,5], [572,6], [658,7], [744,8], [830,9],[916,10],[1002,10],[1088,11],[1174,12]]" :scrollPerPage="false" v-if="$store.state.popcomps !== undefined">
            <slide  v-for="(row,i) in $store.state.popcomps" :key="i" style="height:72px" >
               
                <router-link :to="{name:'events', params: {eventparams: row.CoID + '-1-1' }}" >
                    
                            <div class="qhighlight" style="height:67px;border-radius: 10px; border: 1px solid #e0e0e0; width:86px;text-overflow:hidden;">
                                <div style="padding-bottom:3px"><img class="qflagsmall" height="16px" width="16px" :src="'/assets/images/flags/svg/' + row.Co + '.svg'" :alt="row.Co"/></div> 
                                <div class="qsmallfont qborderlink text-center" style="padding:0px 0px 0px 0px;width:80px;height:50px;display: -webkit-box; -webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow:hidden;text-overflow: ellipsis;">{{row.Comp}}</div>
                            </div>
                            
                </router-link>
             
            </slide>
        </carousel>
        </div>
        <div class="cell shrink" style="margin-right:25px"></div>

    </div>
     
</div>
    

</template>


<script>

    import { Carousel, Slide } from 'vue-carousel';

    export default {

        name: "menuitems",
        components: {
            Carousel, Slide
        },
        data: function () {
            return {
                clsname: this.cn,
                ttype: this.templatetype
            }
        },
        props: {
            cn: String,
            templatetype: { type: Number, Default: 0 }
        }

    }
</script>

<style scoped>

    .VueCarousel-slide {
      
        height: 77px;
        text-align: center;
        padding: 3px;
        padding-top:5px;
      
    }

    .ci {
        display: block;
        margin: 0 auto;
    }

/* 
     #circularimage {
        background: #efefef;
        width: 30px;
        height: 32px;
        border-radius: 50%;
        display: flex; 
        justify-content: center;
        align-items: center;
        text-align: center; 
    
    }  */

    .speccol {
        display:table-cell;
        vertical-align:middle;
        padding-left:20px;
    }








</style>
