<template>
        <div class="cell small-10" style="padding:3px">
        
            <h1 class="cell-auto  text-center" v-if="searchtype!='full'"><router-link :to="{ name: 'graph' }" class="qmajorheader"> {{ langConv(siteName('[sitename]') +  ' Bets') }}</router-link></h1>
            <h1 class="cell-auto  text-center qmajorheader" v-if="searchtype=='full'">{{ langConv(siteName('[sitename]') +  ' Bets') }}</h1>

            <h2 class="qsubheader" v-if="searchtype=='full'">{{ langConv('Check out our past bets in charts or downloadable text to improve your betting strategy') }}</h2>

            <template v-if="searchtype!='full'">
                <summarystats></summarystats>
            </template>    
            <!-- search criteria that appears at top of page -->
            <searchcriteria  @criteria="createChartData" searchtype="full"  v-if="searchtype=='full'">
                <template v-slot:dateonly>
                    <br/>
                </template>
            </searchcriteria>

            <searchcriteria  @criteria="createChartData" searchtype="dateonly" v-if="searchtype=='dateonly'">
                <template v-slot:full>
                    <br/>
                </template>
            </searchcriteria>

            <div class="grid-x qsubheader qlargefont"  @click="showgraph = !showgraph"  v-if="searchtype != 'dateonly'">
                <div class="cell small-1">Graph</div>
                <div :class="{uprotate: !showgraph, downrotate: showgraph }"  class="cell auto" style="font-size:12px">&#9660;</div>
            </div>

                <div v-show="showgraph">
                    <template v-if="chartdata != null">

                        <template v-if="ctype === 'line'">
                            <line-chart :data="chartdata" :options="chartoptions" ></line-chart>
                        </template>
                        <template v-else-if="ctype === 'bar'">
                            <bar-chart :data="chartdata" :options="chartoptions" ></bar-chart>
                        </template>

                    </template>

                    <nodata v-if="chartdata === null"> </nodata>

                </div>
                
                <div style="text-align: center;margin-top: 50px;" v-if="!$store.state.signedin && showresults && searchtype=='full'">
                    <div class="qlargefont"><img  :src="require('/public/assets/images/lock-26.png')" style="width:20px;height:20px;margin-right:20px" alt="" /><span>Login to gain access to bet details</span></div>
                </div>
                
                <div :class="{overlay: !$store.state.signedin}"  >
                   
                <div class="grid-x qsubheader qlargefont " @click="showresults = !showresults"  v-if="searchtype != 'dateonly'"  >
                    <div class="cell small-1">{{ langConv('Results') }}</div>
                    <div :class="{uprotate: !showresults, downrotate: showresults }"  class="cell auto" style="font-size:12px">&#9660;</div>
                </div>

                
                    <template v-if="showresults && searchtype=='full'">
                    
                    <div class="grid-x small-offset-7" style="padding-top:5px" v-if="chartdata != null">
                        <div class="button small cell auto" >
                            <JsonExcel class="btn btn-default" :data="exceljson"  worksheet="Results"  type="csv" :name="chartoptions.plugins.title.text + '.xls'">{{ langConv('Export to Excel')}}</JsonExcel>
                        </div>
                    </div> 

                     <div class="qsmallfontplus1 ">
                        <template v-if="chartdata != null">
                        <div class="grid-x qhighlight">
                            <div class="cell small-2">{{ langConv('Date')}}</div>
                            <div class="cell small-2">{{ langConv('Returns')}}</div>
                            <div class="cell small-6">{{ langConv('Description')}}</div>
                            <div class="cell small-2">{{ langConv('Outcome')}}</div>
                            <div class="cell"/>
                        </div>

                        <div v-for="(strategy, i1) in chartdata.datasets" :key="i1">
                            <b>{{strategy.label}}</b>
                            <div v-for="(row, i2) in strategydata(i1)" :key="i2">
                                <div class="grid-x">
                                    <div class="cell small-2">{{row.x}}</div>
                                    <div class="cell small-2">{{currency(row.y)}}</div>
                                    <div class="cell small-6">{{$spaceandclean(row.Title)}}</div>
                                    <div class="cell small-2">{{row.Score1}} - {{row.Score2}}</div>
                                </div>
                            </div>
                        </div>
                        </template>
                    </div>

                    <nodata v-if="chartdata === null">{{  chartdata }}</nodata>
                
                    </template>
                </div>
                
                

    </div>



</template>


<script>

   import LineChart from "../components/linechart.vue"
   import searchcriteria from "./searchcriteria.vue"
   import BarChart from '../components/barchart.vue'
   import JsonExcel from "vue-json-excel";
   import nodata from '/src/components/components/nodata.vue'
   const summarystats = () => import('/src/components/components/summarystats.vue');

export default {

        data: function () {
            return {

                ctype: this.graphtype,
                chartdata: null,
                chartdataarray: [],
                chartstart: '1/1/1977',
                chartend: '1/1/2099',
                lasttooltiplabel: '',
                period: 'day',
                showgraph: true,
                showresults:true,
                chartoptions: {

                      
                            elements : {
                                point: {
                                    radius: 1,
                                    hoverRadius: 10
                                }
                            },
                    

                        responsive: true,
                        maintainAspectRatio: false,

                        plugins: {

                            title: {
                                display: true,
                                text: ''
                            },

                            legend: {
                                false: true,
                                onClick: this.legendClicked,
                                labels: {
                                    usePointStyle: true,
                                    pointStyle: 'rect',

                                    filter: function(legendItem, data) {

                                    // dont show a label from the profit line but do for overall profit
                                    if(!legendItem.text.includes("profit") || legendItem.text.includes("Cumulative profit")) {
                                        
                                        //legendItem.text = conv
                                        return legendItem.index != 1
                                    }
                                    

                                    }
                                }
                            },

                            tooltip: {

                                callbacks: {

                                    title: this.tooltipheader,
                                    label: this.tooltiplabel,
                                    footer: this.tooltipfooter

                                }
                            }

                        },

                        scales: {

                            y: {
                                ticks: {

                                    callback: function (value, index, values) {
                                        return new Intl.NumberFormat(navigator.language, { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(value).replace(/,/g, '');
                                    }
                                },
                          
                                },

                            x: {
                                type: 'time',
                                time: {
                                    unit: 'day',
                                    round: 'day'
                                },
                                ticks: {
                                    min: '1/1/1977',
                                    max: '1/1/2099'
                                },
                               
       
                           }
                        }
                },
                exceljson : []
                }
            },


        props: {
          searchtype: {
              default:'full',
              type:String
          },
          graphtype: {
              default: 'line',
              type:String

          }
        },

        components: {
            searchcriteria,
            LineChart,
            BarChart,
            JsonExcel,
            nodata,
            summarystats
        },

        methods: {


            strategydata(i) {

                return this.chartdata.datasets[i].data

            },

            legendClicked: function(e, legendItem, legend) {


                const index = legendItem.datasetIndex;
                const ci = legend.chart;
                const type = legend.chart.config.type;


                if (ci.isDatasetVisible(index)) {

                    ci.hide(index);
                    //also remove the data for the profit line if chart type is line
                    if (type === 'line') ci.hide(index+1);
                    legendItem.hidden = true;

                } else {

                    ci.show(index);
                    //also remove the data for the profit line if chart type is line
                    if (type === 'line') ci.show(index+1);
                    legendItem.hidden = false;
                }



            },

            tooltipheader: function (context) {

                    var dateformat;
                    var prefix;

                    switch (this.period) {
                        case "day":
                            prefix = ''
                            dateformat = this.$dateformattype.datemonthday
                            break;
                        case "week":
                            prefix = 'Week ending '
                            dateformat = this.$dateformattype.datemonthday
                            break;
                        case "month":
                            prefix = 'Month of '
                            dateformat = this.$dateformattype.monthyear
                            break;
                        case "year":
                            dateformat = this.$dateformattype.yearonly
                            break;

                    }
                    // if (this.period === "line") {  dateformat = this.$dateformattype.datemonthday}
                    // if (this.ctype === "bar") {  dateformat = this.$dateformattype.monthyear}


                    return prefix + this.$displayDate(context[0].label, dateformat);

            },

            //dont want the default tooltip body to appear
            tooltiplabel: function(context) {

                return

            },

            tooltipfooter: function(context) {

                         let label = context[0].dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context[0].parsed.y !== null) {
                            label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(context[0].parsed.y);
                        }

                        return label;

            },

            //get bet results for that bet
            createChartData: function (fromdate,todate,selectedusers,selectedstrategies,trend, individualbets, topn, Ascend, searchdescription, charttype, period) {

                this.period = period; //for use with this component

                let startdate = fromdate; // these two basically stringfy the date so that can be sent to web api
                let enddate = todate;
                let selusers = JSON.stringify(selectedusers);
                let selstrategies = JSON.stringify(selectedstrategies);

                //needed to reset the whole chart
                this.chartdata = null;

                let params = { startdate, enddate, trend, individualbets, topn, Ascend, selusers,selstrategies, period, searchdescription};

                let url = process.env.VUE_APP_WEBAPI  + 'GraphService.svc/Graph_ByUser';

                this.genericresultset(url, params).then(response => {

                    if (response.length == 0) {
                         this.chartdata = null
                    } else {

                        //determine chart type and time unit and rounding
                        this.ctype = charttype;

                        this.chartoptions.scales.x.time.unit = period

                        //change the start and end value of the x
                        this.chartoptions.scales.x.ticks.min = startdate
                        this.chartoptions.scales.x.ticks.max = enddate

                        //small chart settings?
                         if (this.searchtype == "dateonly") {
                           // this.chartoptions.scales.y.scaleLabel.display = false;
                            this.chartoptions.scales.y.ticks.fontSize = 8;
                            this.chartoptions.scales.x.ticks.fontSize = 8;
                        }

                        //change unit and rounding depending on whether a bar chart

                        //change the interval depending on the period
                         var interval = (enddate - startdate);


                        //title of the chart
                        let  dateoptions = {day: 'numeric', month: 'short', year: 'numeric'};
                        this.chartoptions.plugins.title.text = this.langConv("Profit by Bet Strategy for bets placed")  + " " + startdate.toLocaleDateString("en-US", dateoptions) + " and " + enddate.toLocaleString("en-US", dateoptions) ;

                        this.chartdata = response;

                        //iterate through through dataset langConv any labels
                        this.chartdata.datasets.forEach(row => {
                            row.label = this.langConv(row.label)
                        });

                        this.chartdataarray = this.chartdata.datasets

                        //need routine that flattens the data so takes the label for first level of array and add to the 2nd level values (that need names that make sense in spreadsheet not x and y)

                        for (let dataset in this.chartdataarray) {


                                for (let dataelement in this.chartdataarray[dataset].data)
                                {
                                       let jsonobj = new Object();

                                       jsonobj.date = this.chartdataarray[dataset].data[dataelement].x
                                       jsonobj.strategy = this.chartdataarray[dataset].label
                                       jsonobj.game = this.chartdataarray[dataset].data[dataelement].title
                                       jsonobj.score1 =  this.chartdataarray[dataset].data[dataelement].score1
                                       jsonobj.score2 =  this.chartdataarray[dataset].data[dataelement].score2
                                       jsonobj.netwinnings =  this.chartdataarray[dataset].data[dataelement].y

                                       this.exceljson.push(jsonobj);

                                }
                        }

                    }

                },

                  (error) => { console.log(error) }

                );
                // TO ERROR HANDLING / NOTHING RETURNED (MESSAGE INSTEAD OF GRAPH)

            }

        }

}


</script>

<style>

.overlay {
   
    pointer-events:none;
    background:none !important;
    z-index: -1;
    opacity: 5%
}

    .show {
        visibility:  visible;
    }

    .hide {
        visibility: hidden;
    }

  
    .line {
        height: 3px;
        width: 25px;
        display: inline-block;
        margin-right: 10px;
    }
    ul.tooltipul {
        list-style-type: none;
    }
    li.tooltipli {
        margin-left: 10px;

    }
    /* .legendtext {
        font-size:5px;
    } */
    .calwidth {
    width:100px;
    }

.button-group input{
    display: none;
  
  
  }
  .button-group.small .tiny .button{
      font-size: 10px;
  }



 


</style>