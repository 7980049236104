<template>

<div >
    <template v-if="!linkbutton">
        <div :style="{backgroundColor: buttoncolour}"   style="border-radius: 7px;padding:3px;margin:1px 0px 1px 0px; font-size:10px;text-align: center;">
            <div  v-if="routerlinkpath!=''">
                <router-link class="whitetext" :to="routerlinkpath" style="margin: 2px;" >{{langConv(buttontext)}}
                    <!-- <span style="margin:0px 1px 0px 1px;width:15px;height:15px;font-weight:bold;background-color:white;border-radius: 50%; display: inline-block;color: #0d0b38;font-size: 10px;">&#x279C;</span> -->
                </router-link>
            </div>
            <div v-else>
                <a class="whitetext" :href="btnurl" target="_blank" style="margin:2px" >{{langConv(buttontext)}}
                    <!-- <span style="margin:0px 1px 0px 1px;width:15px;height:15px;font-weight:bold;background-color:white;border-radius: 50%; display: inline-block;color: #0d0b38;font-size: 10px;">&#x279C;</span> -->
                </a>
            </div>

        </div>
    </template>
    <template v-else>
        <div >
            <div   v-if="routerlinkpath!=''">
                <template >
                <router-link  class="qgreylink qsmallfont" :to="routerlinkpath" >{{langConv(buttontext)}}
                    <span class=" qarrow  rightarrow"></span>
                </router-link>
            </template>
            </div>
            <div v-else>
                <a  :href="btnurl" target="_blank" class="qgreylink qsmallfont" >{{langConv(buttontext)}}
                    <span class="qarrow  rightarrow"></span>
                </a>
            </div>

        </div>
       
    </template>
</div>

</template>

<script>


    
    export default {
        name: "actionbutton", 
        data: function data() {
                return {
                    btnurl: "", 
                    btext: ""        
                }
         },

        mounted: function mounted() {

                this.buttonhref();
        },
    
        components: {
            
        },
        props: {
            
            bookmaker: { type: String, default: ''},
            buttontext: { type: String, default: 'Get Offer'},
            url: { type: String, default: ''},
            buttoncolour: {type: String, default: '#00dce2'},
            routerlinkpath: {type: String, default: ''},
            linkbutton: {type: Boolean, default: false}
            
        },
        methods: {
            buttonhref() {
               
                if (this.url != "") {this.btnurl = this.url } else { this.btnurl = '/bookmakerloader.html?bkm=' + this.bookmaker + '&country=' + this.$store.state.countryname}


            }

        },
    }





</script>

<style>
    .whitetext {
        color:white;
        font-weight:600;
    }



</style>
