<template>
<div style="width:100%; margin: 0 auto; height: auto; position: relative">
<div   style="background-color:#007ea7; text-align:center" v-if="results.length!=0&&type=='across'">


                        <ul  style="display: inline-block;margin:0;padding:0; height: 2em" v-for="(row,i) in results" :key="i">

                            <!-- does it have a badge or label colour -->
                        <template v-if="row.MenuLevel != 2">
                            <router-link :to="({name: row.RouterLink, params:{param1: row.ParamValue}})" class="qsmallfont " >
                         
                                <li v-if="row.Colour!=null"  class="qsmallfontplus1 qfeaturespacing qwhitelink"  ><span  :style="[{backgroundColor: row.Colour}]" style="padding:3px">{{ langConv(siteName(row.Title))}}</span>
                                    <span if="row.Title=='Free Bets'" class="badge alert">{{ $store.state.nopromo }}</span>
                                    <span  :class="{tooltip: hints}" data-nosnippet v-if="row.Description != ''">
                                    <span class="tooltiptext" :class="[{'tooltip-right': i < (results.length / 2)}]" v-html="langConv(row.Description)"></span>
                                    </span>
                                </li> 

                                <li v-else-if="row.Title=='OG Bets'" class="dropdown qsmallfontplus1 qfeaturespacing qwhitetext"  @mouseover="enableMenu('ogbets')" @mouseleave="disableMenu('ogbets')" > {{ langConv(siteName(row.Title))}}
                                    
                                    <span  :class="{tooltip: hints}" data-nosnippet v-if="row.Description != ''">
                                        <span class="tooltiptext" :class="[{'tooltip-right': i < (results.length / 2)}]" v-html="langConv('Our own Bets')"></span>
                                    </span>
                                    <div  id="ogbets" class="dropdown-content-center qsmallfontplus1" style="background-color:#007ea7; text-align:center">
                                       
                                        <router-link class="qwhitelink" :to="({name: 'graph'})">
                                            <div class="qsmallfontplus1" style="padding:5px"> {{ langConv(siteName('Past Bets')) }}
                                                <span  :class="{tooltip: hints}" data-nosnippet>
                                                <span class="tooltiptext" :class="[{'tooltip-right': i < (results.length / 2)}]" v-html="langConv('The bets and users have placed by strategy')"></span>
                                                </span>
                                            </div>
                                        </router-link>  
                                        
                                        <router-link class="qwhitelink" :to="({name: 'pendingbets'})">    
                                        <li  class="qsmallfontplus1 qwhitelink"> {{ langConv(siteName('Current Bets'))}}
                                            <span  :class="{tooltip: hints}" data-nosnippet>
                                            <span class="tooltiptext" :class="[{'tooltip-right': i < (results.length / 2)}]" v-html="langConv('Bets waiting on events that are awaiting to happen. These are our bets not tips')"></span>
                                            </span>
                                        </li>
                                        </router-link> 
                                    
                                    </div>
                                </li>

                                <li v-else-if="row.Title=='Betting Tools'" class="dropdown qsmallfontplus1 qfeaturespacing qwhitetext" @mouseover="enableMenu('ogcalc')" @mouseleave="disableMenu('ogcalc')" > {{ langConv(siteName(row.Title))}}
                                    
                                    <span :class="{tooltip: hints}" data-nosnippet v-if="row.Description != ''">
                                        <span class="tooltiptext" :class="[{'tooltip-right': i < (results.length / 2)}]"  v-html="langConv('Betting tools menu')"></span>
                                    </span>
                                    <div  id="ogcalc" class="dropdown-content-center  qsmallfontplus1" style="background-color:#007ea7">
                                        <router-link :to="({name: 'hutchdutchcalc'})" class="qsmallfont qwhitelink ">
                                            <div  class="qsmallfontplus1" style="padding:5px"> {{ langConv(siteName('Betting Calculator')) }}
                                                <span  :class="{tooltip: hints}" data-nosnippet>
                                                <span class="tooltiptext" :class="[{'tooltip-right': i < (results.length / 2)}]" v-html="langConv('Calculator to help you keep track of the odds')"></span>
                                                </span>
                                            </div>
                                        </router-link>      
                                        
                                    </div>
                                </li>



                                <li v-else  class="qsmallfontplus1 qfeaturespacing qwhitelink"  ><span style="padding:3px" :style="[{backgroundColor: row.Colour}]">{{ langConv(siteName(row.Title))}}</span>
                                  
                                    <span  :class="{tooltip: hints}" data-nosnippet>
                                    <span class="tooltiptext" :class="[{'tooltip-right': i < (results.length / 2)}]"  v-html="langConv(row.Description)"></span>
                                    </span>
                                </li>     


                            </router-link>
                        </template>
                        </ul>
                              

</div>
<div class="grid-x grid-padding-x grid-padding-y qbordercolour"  v-if="results!=null&&type=='down'" >

            <ul  class="dropdown-content-left qbordercolour qsmallfontplus1"  >

                    <li v-for="(row,i) in results" :key="i">
                        <template v-if="row.MenuLevel != 0">
                            <!-- does it have a badge or label colour -->

                            <router-link class="qbuttonhover" @click.native="click(false)" :to="({name: row.RouterLink, params:{param1: row.param1}})">

                                <div v-if="row.Colour!=null" class="label" :style="[{backgroundColor: row.Colour}]">{{langConv(row.Title)}}</div>
                                <div v-else class="qwhitetext">{{ langConv(siteName(row.Title))}} 
                                <span  :class="{tooltip: hints}" >
                                    <span class="tooltiptext" :class="[{'tooltip-right': i < (results.length / 2)}]">{{langConv(row.Description)}}</span>
                                </span> </div>
                                <div v-if="row.Title=='Free Bets'" class="badge alert">{{$store.state.nopromo}}</div>

                            </router-link>
                        </template>

                    </li>
            </ul>
</div>
</div>
</template>

<script>
    export default {
        name: "features",
        data() {
            return {

                results: []

            }

        },
        props: {
            type: {type: String, default: 'across'}
        },
        created() {

            this.GetData();

        },
        computed: {
            hints () {
                return this.$store.state.showhints;
            }
    

        },



        methods: {


            enableMenu: function (val) {

                if (val=='ogbets' || val=='ogcalc') {
                    document.getElementById(val).style.display = "block";
                }
                    
            },

            disableMenu: function(val) {
                if (val=='ogbets' || val=='ogcalc') {
                 document.getElementById(val).style.display = "none";
                }
            },

            click: function(val) {

                 if (val == false ) {
                     document.getElementById("menudown").style.display = "none"
                }
    

            },

            GetData: function() {
                this.localJSONLoad(process.env.VUE_APP_DATA + "featuresacross.json").then(response => {
                    this.results= response
               });

            }
        }
    }
</script>

<style scoped>

.badge {
    position: relative;
    left: -5px;
    top: -10px;
    font-size:0.6em;
}


</style>