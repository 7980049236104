<template>
    <div class="cell small-12 large-10" style="margin:2px">
        <div class="grid-x">
            <h1 class="cell qmajorheader qalignmiddle">
                {{langConv($store.state.oats[$store.state.selectedoat].D)}}
            </h1>
            
    
            <h2 class="cell qsubheader qalignmiddle qsmallfontplus1" >
                {{langConv($store.state.oats[$store.state.selectedoat].Explanation)}}
                        
            
                            </h2>
        </div>
            
        <div class="grid-x qsubheader" style="padding:6px;">

                <div class="cell small-6 align-self-middle">
                    <calendarcomponents @dataparams="filterDataViewbyDate"></calendarcomponents>
                </div> 
                <div class="cell small-1 align-self-middle">
                
                </div>
                <div class="cell small-5 align-self-middle">
                    <dropdown ocname="selectsports" @sport_values="createSportsFilter"  :ocdefaultvalue="langConv('Filter by Sport')" :ocoptions="$store.state.sports" :ocnoitems="999" :occloseonselect="false" :ocmultiselect="true"  :ocshowlistonstart="false" :ocheightoflist="350"></dropdown>
                </div>

        </div>

           
        <div class="hide-for-large" style="margin-top:5px;margin-bottom: 2px;" >

                <carousel  :navigationEnabled="false" :paginationEnabled="false"  :perPageCustom="[[400,3],[500,4],[600,5],[700,6],[800,7]]">

                    
                    <slide v-for="(rowa,i) in $store.state.oats" :key="i" style="height:30px">
                       
                    <div class="qbuttonhover" style="margin-right: 2px;margin-left:2px">
                     
                        <template v-if="rowa.requirelogin == false || $store.state.signedin == true" >
                          
                            <div style="min-height:30px;padding:6px" class="text-center qsmallfont" :class="{'qactivepress':i==$store.state.selectedoat, 'qinactivepress':i!=$store.state.selectedoat}"  v-on:click="changeDataView(i)" v-if="rowa.requirelogin == false || $store.state.signedin == true"> {{langConv(rowa.D)}}</div>
                        </template>
                        <template v-if="rowa.requirelogin == true && $store.state.signedin == false">
                            <div style="min-height:30px;padding:6px" class="text-center qsmallfont" :class="{'qactivepress':i==$store.state.selectedoat, 'qinactivepress':i!=$store.state.selectedoat}">{{langConv(rowa.D)}} <img class="requireloginimg qwhiteicon" style="height:18px; padding-left:5px" :src="require('/public/assets/images/lock-26.png')" alt="Log in to access this feature" /></div>
                        </template>
                    </div>

                    </slide>
                    
                </carousel>
        </div>
        
         
        <div class="grid-x">
                <div class="cell large-2 show-for-large">
                    <ul class="vertical menu icons icons-left">
                        <li v-for="(row2,i) in $store.state.oats" :key="i"  class="qbuttonhover">
                      
                        <template v-if="row2.requirelogin == false || $store.state.signedin == true">
                            <div class="qalignleft qsmallfontplus1" style="padding:5px;border-bottom: 1px solid white;" :class="{'qactivepress':i==$store.state.selectedoat, 'qinactivepress':i!=$store.state.selectedoat}" v-on:click="changeDataView(i)" v-html="langConv(row2.D)">
                            </div>
                        </template>
                        <template v-if="row2.requirelogin == true && $store.state.signedin == false">
                            <div class="qalignleft qsmallfontplus1" style="padding:5px" :class="{'qactivepress':i==$store.state.selectedoat, 'qinactivepress':i!=$store.state.selectedoat}" >{{langConv(row2.D) }}<img class="qrequireloginimg qwhiteicon" style="margin-bottom:3px;margin-left:5px" :src="require('/public/assets/images/lock-26.png')" ></div>
                        </template>      
                            
                        </li>   
                    </ul>
                </div> 
                <div class="cell small-12 large-10">
                    <dataviews :spname="$store.state.oats[$store.state.selectedoat].param1" :sportid="0"  :selspor="selectedsports"  :startdate="startdatevalue" :nodays="nodays" :key="filterchange"></dataviews>
                </div>
        </div>
         

    </div>


</template>

<script>

   import dataviews from  '../components/components/dataviews.vue';
   import dropdown from '../components/navigation/dropdown.vue';
   import { Carousel, Slide } from 'vue-carousel';
   import calendarcomponents from '../components/components/calendarcomponents.vue';

    export default {
        name: "oddsanalysis",
        data: function () {
            return {
                selectedsportsprov: [],
                selectedsports: [],
                filterchange: 0,
                startdatevalue: new Date(),
                nodays: 1
            }       
        },
        components: {
            dataviews,
            dropdown,
            Carousel, Slide,
            calendarcomponents
        },
        methods: {

                changeDataView: function(i) {
                  
                    // this.activeIndex = i;
                     this.$store.commit("setOat",i);

                },  

                filterDataView: function() {
                    // simply  change the key on the on the dataview component
                    this.filterchange += 1;
                },
                
                createSportsFilter: function(val) {
                    
                    this.selectedsports = val;
                    this.filterDataView();
                    
                },

                filterDataViewbyDate: function(dateparams) {

           
                  
                        this.startdatevalue = dateparams.startdate;
                        this.nodays = dateparams.nodays;
                        this.filterDataView();

                }     
        }
    }
</script>

<style scoped>
.active {
    color:black;
    cursor:default;
    padding:5px;
    margin:5px;
    border-bottom: 2px solid red;
    text-align: center;
    font-size:small;
    

}
li {
    cursor:pointer;
}

.inactive {
    background-color:white;
    padding:5px;
    margin:5px;
    text-align:center;
    font-size: small;
    color:black;

}

</style>