<template>
    <div>
        <H1>No such page</H1>
    <div>
        {{ $route.params.pathMatch}}
    </div>
    </div>


</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>