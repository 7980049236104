
import axios from 'axios'
import OddsConverter from 'odds-converter';



export default {

    install(Vue, options) {

        //global mixins
        Vue.mixin({
            computed: {
                unique: function () {

                    return function (arr, key, wherefield1, whereval1, wherefield2, whereval2) {

                        try {

                            //is array empty or null
                            if (arr === undefined || arr.length == 0) {
                                alert(key + ' - ' + wherefield1 + ' - ' + whereval1);
                            }

                            var output = []
                            var usedKeys = {}
                            var addval = true
                            for (var i = 0; i < arr.length; i++) {
                                if (!usedKeys[arr[i][key]]) {

                                    //is there a where field and value
                                    if (wherefield1 !== null && whereval1 !== null && typeof wherefield1 !== 'undefined' && typeof whereval1 !== 'undefined') {
                                        if (arr[i][wherefield1] == whereval1) {
                                            //is there a second where field and value
                                            if (wherefield2 !== null && whereval2 !== null && typeof wherefield2 !== 'undefined' && typeof whereval2 !== 'undefined') {
                                                if (arr[i][wherefield2] == whereval2) {
                                                    addval = true;
                                                } else { addval = false }
                                            } else { addval = true }
                                            addval = true;
                                        } else { addval = false }
                                    }

                                    if (addval == true) {
                                        usedKeys[arr[i][key]] = true
                                        output.push(arr[i])
                                    }

                                    addval = true;
                                }
                            }

                            return output
                        }

                        catch (err) {

                            alert(err);
                        }
                    }
                }
            }
        })

        Vue.mixin({
            methods: {

                setSelectedUserFavourites: function (cid,cdesc, addto) {

                    let existingfavs = this.$store.state.favourites;

                    // create object to put into array 
                    const cmpidanddesc = { competitorid: cid, Description: cdesc}

                    if (addto == true) {

                        existingfavs.push(cmpidanddesc)
                        
                    } else {

                        for (const index in existingfavs) {
                            if (existingfavs[index].competitorid == cid) {
                                if (index > -1) existingfavs.splice(index, 1);
                            }
                          }    
                    
                    }

                    this.$store.commit("setFavourites",existingfavs)

                    //now save back to webservice

                    //Only allow 20 to be saved to start with
                    if (this.$store.state.favourites.length <= 20) {

                        //save back to webservice

                    }

                },

                //Default country settings
                setDefaultCountrySettings: function () {

                        this.$store.commit("setCountry", 'EN');
                        this.$store.commit("setCountryName", 'United Kingdom');
                        this.$store.commit("setCurrency", 'GBP');
                          
                        //to do set regbodies from this default 

                        let regbodies = [];
                        regbodies.push[281]; // 281 is united kingdom 

                        this.$store.commit("setRegBody", regbodies);

                        this.$store.commit("setNoPromo", 0);

                        this.$store.commit("setLangFileLoaded", true);  
                        
                        //this.setSelectedBookmakerswithLicense(); - old when linked up to license bodies instead of licenses for country
                        this.setSelectedBookmakerswithCountryLicense();
                    

                       this.nowLoadAds();
                },

                setCurrencyforCountry: function () {

                    //get the saved countrycode
                    let countrycode = this.$store.state.country;


                    this.localJSONLoad(process.env.VUE_APP_DATA + "currency.json").then(response => {

                        let currencies = response
                        
                        let countriesCurrency = currencies.filter(recs => recs.alpha2 == countrycode )

                        this.$store.commit("setCurrency", countriesCurrency[0].currencies[0]);

                   });

                   // let currencies = this.bookmakerlicenses = require("/public/assets/data/currency.json");   

                   // let countriesCurrency = currencies.filter(recs => recs.alpha2 == countrycode )

                   // this.$store.commit("setCurrency", countriesCurrency[0].currencies[0]);

                },

                numbertoCurrency: function (val) {


                    let formattedcurrency = ''

                    // has currency got a symbol
                    let currency = this.$store.state.currency

                    

                    switch (currency) {
                        case 'GBP':
                            formattedcurrency = '£' + val; 
                            break;
                        case 'EUR':
                            formattedcurrency = '€' + val; 
                            break;
                        case 'USD':
                            formattedcurrency = '$' + val; 
                            break;
                        default:
                            formattedcurrency = val;
                            break;
                    }

                   
                        return formattedcurrency
                    

                },


                async setSelectedBookmakerswithCountryLicense() {
     
                    // let bl = require("/public/assets/data/bookmakercountrylicenses.json");
                    let sbtemp = [];
                    let sb = [];

                    this.localJSONLoad(process.env.VUE_APP_DATA +  "bookmakercountrylicenses.json").then(response => {

                        let bl = response
                                            
                        //insert all the bookers that have a license 
                        this.$store.state.regbody.forEach(function(regbodyitem) {

                            sbtemp = bl.filter(recs => recs.LID == regbodyitem);
                            sb = sb.concat(sbtemp)
                        
                        })

                        const key = "D"

                        sb = [...new Map(sb.map(item =>[item[key], item])).values()];

                        //set the bookmakers to be the selected bookmakers
                        this.$store.commit("setSelectedbookmakers",sb)

                        this.nowLoadAds();

                        return true;
                
                    });
                },



                //set selected bookmakers in the store dependant on the default regulator for the country
                // or those selected by
                async setSelectedBookmakerswithLicense () {


                    let sbtemp = [];
                    let sb = [];

                    this.localJSONLoad(process.env.VUE_APP_DATA + "bookmakerlicenses.json").then(response => {
                    
                    // let bl = require("/public/assets/data/bookmakerlicenses.json");
                        let bl = response

                        //insert all the bookers that have a license 
                        this.$store.state.regbody.forEach(function(regbodyitem) {
                            sbtemp = bl.filter(recs => recs.LID == regbodyitem);
                            sb = sb.concat(sbtemp)
                        })

                        const key = "D"

                        sb = [...new Map(sb.map(item =>[item[key], item])).values()];

                        //set the bookmakers to be the selected bookmakers
                        this.$store.commit("setSelectedbookmakers",sb)
                    
                        this.nowLoadAds();

                        return true;
                    })
                },


                genericConfirm: function (Question) {

                    this.$confirm(
                        {
                            message: Question,
                            button: {

                                yes: 'Ok'
                            },

                        }
                    )

                },
                genericYesNo: function (Question) {

                    return new Promise((resolve) => {
                        this.$confirm(
                            { 
                                message: Question,
                                button: {
                                    no: 'No',
                                    yes: 'Yes'
                                },

                                callback: confirm => {
                                    resolve(confirm);
                                }
                            }
                        )
                    }
                    );
                },
                //get (all) users with bets
                

                getUsers: function () {

                    // return new Promise((resolve) => {

                    var URL = process.env.VUE_APP_WEBAPI + "FeatureTables.svc/GetUsers";

                    axios.get(URL, {

                        params: {}

                    }).then(response => {

                        this.$store.commit("setUsers", JSON.parse(response.data));                  

                    }),
    

                    (error) => {
                   
                        resolve(false)
                    }
                    // })
                

                },
                getStrategies: function () {
                
                    let params = {};
                    let url = process.env.VUE_APP_WEBAPI  + 'FeatureTables.svc/RetrieveBetStrategies';
    
                    this.genericresultset(url, params).then(response => {
                        this.strategies = response
                    },
                      (error) => { console.log(error) }
                    );
    
    
                },


            //get history bets for search results.
            searchTitle(input) {
                
                
                if (input.length < 3) {
                  return [];
                }
        
                
                var URL = process.env.VUE_APP_WEBAPI + "FeatureTables.svc/SearchItems";
          
                return new Promise((resolve) => {
                  axios
                    .get(URL, {
                      params: {
                        desc: input,
                        noitems: 50,
                        wildcard: true,
                        sportid: -1 
                      },
                    })
                    .then(
                      (response) => {
          
                        let data = JSON.parse(response.data)[0];
          
                        if (Object.keys(data).length > 1) {
                          data = data.BO;
                        } else {
                          data = [];
                        }
                        return resolve(data);
                      },
          
                      (error) => {
                        console.log(error);
                      }
                    );
                });
              },

            setSearchValue: function (input) {
                //clear the searchval if nothing returned
                if (input === undefined) {
                    this.searchval = '';
                } else {
                    this.searchval = input.event;
                }

            },

            
            //get full data for search results
            searchresultsData: function (val) {
           
                this.loadedsearch = false;

                var URL = process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/SearchResults";

                axios.get(URL, {

                    params: {
                        desc: val,//parseInt(this.id), //can get multiple banner sizes by passing the banner number into the array
                        noitems: 100, //parseInt(this.noitems), // does nothing at the moment 18/01/2019
                        wildcard: true,
                        sportid: -1
                    }

                }).then(response => {
                    
                    this.searchresults = JSON.parse(response.data);
                    this.loadedsearch = true;

                    },
                        (error) => { console.log(error) }
                    );
            },

            hintStatus: function () {
             
                return this.$store.state.showhints;

            }


        } 
        
        })

        Vue.mixin({

            data: function () {
                return {
   
                    errorobject: {
                        errormessage: "",
                        error: false
                    },

                }
            },

            methods: {


                mouseQuadrant: function mouseQuadrant(event) { 

                const width = window.innerWidth;
                const height = window.innerHeight;
                const mouseX = event.clientX;
                const mouseY = event.clientY;
            
                let left = false;
            
                if (mouseX <= width / 2 && mouseY <= height / 2) {
                    left = true;
                } else if (mouseX > width / 2 && mouseY <= height / 2) {
                    left = false;
                } else if (mouseX <= width / 2 && mouseY > height / 2) {
                    left = true;
                } else if (mouseX > width / 2 && mouseY > height / 2) {
                    left = false;
                }

                if (left == true) {
            
                    this.$store.commit("setRightSide", true);
                 } else {
                
                    this.$store.commit("setRightSide", false); 
                 }
                 this.$forceUpdate();


                },


                colorandBannerHTML: function colorandBannerHTML(selectedbanner) {
             
                    let bannerarr = []; //add the first element (which is always colour)
                    let banners = this.$store.state.adbanners;


                    bannerarr.push(banners[0]); //add the selectbanner element to the array - this is the colour
                    
                    //iterate through the banner array to find the matching bannernum
                    banners.forEach(function(val) {
                        if(val.BannerEnum == selectedbanner) { bannerarr.push(val)}
                    }
                        

                    )
 
                    
                   // bannerarr.push(this.$store.state.adbanners.slice(selectedbanner, selectedbanner + 1));
             
                    return bannerarr;
               },

                removehyphens: function (val) {


                    let res = val.replace(/-/g, ' ');

                    return res;
                },

                splittoarray(val, splitval) {

                    let res = val.split(splitval);

                    return res;

                },
                newgenericresultset: function (wcfurl, jsonparams,spname) {                 

                        return new Promise((resolve) => {
                        axios.get(wcfurl, {
                            params: {
                                SPName: spname,
                                params: jsonparams
                            }

                        }).then(response => {
                            if (response.data==null || response.data == '') {
                              
                                return resolve('');
                            } else {
                                return resolve(JSON.parse(response.data));
                            }
                        },
                        
                            (error) => {console.log(error) }
                        );
                    });

                },
                localJSONLoad: function (url) {


                    return new Promise((resolve) => {
                    
                        axios.get(url

                        ).then(response => {
                            
                            return resolve(response.data);
                        },

                        (error) => {console.log(error) }
                        );
                    
                    });  
                },

                newgenericpost: function (wcfurl, jsonparams,spname) {
 
                    return new Promise((resolve) => {
                        axios.post(wcfurl, {SPName: spname,Params: jsonparams}).then(response => {

                        //just return out of the method if its returned ok (assume it hass http status:200 to get to here)
                        return 
                    },
                    
                        (error) => {console.log(error) }
                    );
                });
            },

                newgenericjsonpost: function (wcfurl, json,spname) {
                   
                    return new Promise((resolve) => {
                        axios.post(wcfurl, {SPName: spname,Json: json}).then(response => {

                        //just return out of the method if its returned ok (assume it hass http status:200 to get to here)
                        return 
                    },
                    
                        (error) => {console.log(error) }
                    );
                });


            },



                genericresultset: function (wcfurl, paramslist) {

                    return new Promise((resolve) => {
                        axios.get(wcfurl, {

                            params: paramslist

                        }).then(response => {

                            if (response.data!="") {
                                return resolve(JSON.parse(response.data));
                            }{
                                return resolve("");
                            }

                        },
                          
                            (error) => { console.log(error) }
                        );
                    });

                },
                genericpost: function (wcfurl, paramslist) {
                    return new Promise((resolve) => {
                        axios.post(wcfurl,

                            paramslist

                        ).then(response => {

                            return resolve(response.statusText)
                        },
                            (error) => { console.log(error) }
                        );
                    });
                },

                retrieveuser: function () {


                   if (this.$store.state.signedin == true) {
                    return new Promise((resolve) => {

                        axios.get(process.env.VUE_APP_WEBAPI + "SaveRetrieveandDeleteData.svc/RetrieveUserSettings", {
                            params: {

                                email: this.$store.state.email

                            }

                        }).then(response => {

                            //if no data is returned then need to create account
                            if (response.data=='') { this.saveuser();}

                            //save cookie data but only save back to the database
                            let retrieveduser = JSON.parse(response.data); //do not need to JSON.stringify as this has alreasy happened

                            this.$store.commit("setCurrentot", retrieveduser.currentot)
                            this.$store.commit("setBetfaircom", retrieveduser.betfaircom)
                            this.$store.commit("setBetdaqcom", retrieveduser.betdaqcom)
                            this.$store.commit("setSmarketcom", retrieveduser.smarketcom)
                            this.$store.commit("setTimediff", retrieveduser.timediff)
                            this.$store.commit("setTzdesc", retrieveduser.tzdesc)
                            this.$store.commit("setEmail",retrieveduser.email)
                            this.$store.commit("setName", retrieveduser.name)
                            this.$store.commit("setSelectedbookmakers",retrieveduser.sb)
                            this.$store.commit("setNameID", retrieveduser.nameid)
                            this.$store.commit("setisAdmin", retrieveduser.isAdmin)
                            this.$store.commit("setObfuscatedName", retrieveduser.obfuscatedname)



                            if (retrieveduser.regbody != null) { this.$store.commit("setRegBody", JSON.parse(retrieveduser.regbody))}
                            if (retrieveduser.favs != null) {this.$store.commit("setFavourites", retrieveduser.favs)}

                            console.log("RETRIEVEUSER: " + retrieveduser);

                            resolve(true);
                        },

                            (error) => {
                              
                                resolve(false)
                            }
                        );
                        

                    });
                }
                },

                saveuser: function () {

                    return new Promise((resolve) => {

                        //save to database if the user is signed in
                        if (this.$store.state.signedin == true) {
                        
                            axios.post(process.env.VUE_APP_WEBAPI + "SaveRetrieveandDeleteData.svc/UserSettings", {

                                currentot: this.$store.state.currentot,
                                betfaircom: this.$store.state.betfaircom,
                                betdaqcom: this.$store.state.betdaqcom,
                                smarketcom: this.$store.state.smarketcom,
                                timediff: this.$store.state.timediff,
                                tzdesc: this.$store.state.tzdesc,
                                email: this.$store.state.email,
                                name: this.$store.state.username,
                                signin: this.$store.state.signedin,
                                authtype: this.$store.state.authtype,
                                selectedbookmakers: JSON.stringify(this.$store.state.sb),
                                nameid: this.$store.state.nameid,
                                regbody: JSON.stringify(this.$store.state.regbody),
                                favourites: JSON.stringify(this.$store.state.favourites)

                            }).then(response => {
                              
                                resolve(true);
                            },
                                (error) => {
                                    console.log(error),
                                  
                                    resolve(false)
                                }
                            );
                        } else {
                            resolve(true);
                        }

                    });
                },


                getcountry: function () {


                    return new Promise((resolve) => {

                        axios.get("https://ipapi.co/json/", {}).then(response => {

                        
                            //save country to user variable from returned data
                            resolve(response.data);

                        },
                            (error) => {

                                //default set to UK
                                console.log("failed to retrieve country");
                                // default value

                                this.setDefaultCountrySettings();
                                resolve(false);
                            }
                        );
                    });

                },


                getcountrybackup: function () {

                   
                    return new Promise((resolve) => {

                        axios.get("https://geolocation-db.com/json/09ba3820-0f88-11eb-9ba6-e1dd7dece2b8", {}).then(response => {

                 
                            //save country to user variable from returned data
                            resolve(response.data);

                        },
                            (error) => {

                                //default set to UK
                                console.log("failed to retrieve country");
                                // default value

                                this.setDefaultCountrySettings();
                                resolve(false);
                            }
                        );
                    });

                },

                strip_html_tags: function (str) {
                    if ((str === null) || (str === ''))
                        return false;
                    else
                        str = str.toString();
                        return str.replace(/<[^>]*>/g, '');
                },

             async setIPDependantDefaults () {
        
                var regbodies = [] //this.$store.state.regbody;
                var country = "" //this.$store.state.country; // if country (ISO) is set then country name and IPAddress should also be set 
            
                //note: noip means selected bookers set by user( or they have gopne)
                if (this.checkIfValidIP(this.$store.state.ipaddress) == false && this.$store.state.ipaddress != "noip" ){           
                    //at present always check for country

                       this.getcountry().then(response => {  
                        
                        if (response !== false) {

                            this.$store.commit("setCountry", response.country_code);
                            //this.$store.commit("setCountry", "DE");

                            this.$store.commit("setCountryName", response.country_name);
                            //this.$store.commit("setCountryName", "Germany");



                            this.$store.commit("setIPAddress", response.ip);
                        
                            //get the currency from the country
                            this.setCurrencyforCountry();
                
                            this.localJSONLoad(process.env.VUE_APP_DATA + "licensedcountry.json").then(licensedcountryresponse => {
                        
                                //does the country have licensing?
                                //this.venue = require("/public/assets/data/licensedcountry.json");
                        
                                this.venue =licensedcountryresponse 
                                country = this.venue.filter(country => country.Description == response.country_name);  

                                //*****to do what happens when get country fails
                       
                                //a country hads been recognised 
                                if (country.length == 1) {

                                    // new code for regulating via via country check rather thanregulation bodies  03/01/2024
                                    let countryLicenseID = country[0].ID;

                                    regbodies.push(countryLicenseID)
                                    this.$store.commit("setRegBody", regbodies);
                                    this.$store.commit("setNoPromo", country[0].NoPromo);

                                    //only need to load translate if not english
                                    if (country[0].English == false) {
                                        this.loadTranslationFile()
                                    } else {
                                        this.$store.commit("setLangFileLoaded", true); 

                                    }

                                    //set selected bookmakers
                                    this.setSelectedBookmakerswithCountryLicense();
                            
                                } else {

                                    // should we not ask here for authorities? by default set to uk
                                    this.setDefaultCountrySettings();   //country has not been recognised 
                                    this.$forceUpdate();
                        
                                }
                            })
                        }
                    
                        }).catch(error => {
                        

                            this.setDefaultCountrySettings();
                            console.log('cannot retrieve country from IP =' + error)

                            
                        });
                
                }
     
                },

            backtoEnglish (country) {

                const countriesWithOwnLang = ['Germany', 'Ireland']

                if (!countriesWithOwnLang.includes(country)) {

                    return 'United Kingdom' // default to England if not in site language list

                } else {
                    
                    return country
                }


            },  

            //convert val to correct language if exists in 
            langConv (val) {


                var translation = val;


                if (this.$store.state.langtranslationfile)   {
  
                    let translationfile = this.$store.state.langtranslationfile;
             
                    try {
                    let pos = (translationfile.findIndex(row => String(row.English).toLowerCase() == val.toLowerCase())); 

                    let translationline =  translationfile.slice(pos, pos+1);

                    //is there a translation then set
                    if (translationline.length == 1) {translation = translationline[0].other}

                    //if just a . then the foreign lanaguage is the same as the english
                    if (translation == '.') {translation = val}
                    
                    } catch {
                        return translation
                    }

                }

                return translation
                
            },


            loadTranslationFile: function loadTranslationFile() {

                 //retrieve country name from vuex
                 let country = this.$store.state.countryname;

                 this.localJSONLoad(process.env.VUE_APP_DATA + "licensedcountry.json").then(response => {

                    let licensedcountries = response

                    licensedcountries = licensedcountries.slice(licensedcountries.findIndex(row => row.Description.toLowerCase() == country.toLowerCase()), licensedcountries.findIndex(row => row.Description.toLowerCase() == country.toLowerCase())+1)
 
                    //is the country english speaking or not in the licensed country file. Return val else 
                    // let licensedcountries = require("/public/assets/data/licensedcountry.json");
 
                    //load the language alternative file for that country. if not exist return val
                     let licenseforcountry = licensedcountries.slice(licensedcountries.findIndex(row => row.Description.toLowerCase() == country.toLowerCase()), licensedcountries.findIndex(row => row.Description.toLowerCase() == country.toLowerCase())+1)
 
                     if  (licenseforcountry.length == 1) {     
                         // there is a language file for the so see of there is an English match
                        if (licenseforcountry[0].Description == country) {
                        
                         this.localJSONLoad(process.env.VUE_APP_DATA + "languagealternatives " + country + ".json").then((tfile) => {

                            //covert tfile from module TO array
                            let tfile2 = Object.values(tfile)

                            this.$store.commit("setLangTranslationFile",  tfile2);

                            this.$store.commit("setLangFileLoaded", true);
                            
                            console.log(this.$store.state.langtranslationfile);

                         })
                        .catch((err) => {

                               this.$store.commit("setLangFileLoaded", true);          
                                console.log("TSR -  " + err)
                                     
                        });
         
                    }
                 } 
                })

            },


            //load adds after main features loaded
            async nowLoadAds() {

                    var _this = this;
                

                    _this.adbanners = _this.$store.state.adbanners
             
                    if (_this.adbanners.length == 0 ) {

                       
                        ////get data for banners
                        axios.post(process.env.VUE_APP_WEBAPI  + "AdvertService.svc/Banner", {
                            bansizes: this.bannertypes,
                            //can get multiple banner sizes by passing the banner number into the array
                            screenid: 0,// does nothing at the moment 18/01/2019
                            country: this.$store.state.countryname

                        }).then(function (response) {
                     
                            _this.adbanners = response.data.BannerResult
                            _this.$store.commit("setAdbanners", _this.adbanners);                

                           _this.$store.commit("setAdvertsLoaded",true);
                        });
                    } else {

                        _this.$store.commit("setAdvertsLoaded",true);
                        
                    }
                    
                },

                /* Check if string is IP */
                checkIfValidIP: function(str) {
                    try {
                    // Regular expression to check if string is a IP address
                    const regexExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
                    return regexExp.test(str);
                    } catch {
                        return false
                    }
                },

                //load json file into vuex variable array
                initialdataload: function() {

                    //if time zone not already set
                    if (this.$store.state.tzdiff == '' || this.$store.state.tzdiff == undefined ) {

                        //set timezone desc from browser 
                        this.$store.commit("setTimediff", document.querySelector('meta[name="browserUTCdiff"]').getAttribute("content"))
                    
                        //set offset from utc from browser          
                        this.$store.commit("setTzdesc", document.querySelector("meta[name='browserTimeZoneName']").getAttribute("content"))
                    
                    } 

                    //all spports with odds
                    this.localJSONLoad(process.env.VUE_APP_DATA + "sportswithodds.json").then(response => {
                    this.$store.commit("setSports", response);
                    })
                    //favourite competitions currently in db    
                    this.localJSONLoad(process.env.VUE_APP_DATA + "popularcompetitions.json").then(response => {
                    this.$store.commit("setPopComps", response)
                    })
                   
                    //available odds analysis types
                    this.localJSONLoad(process.env.VUE_APP_DATA + "oddsanalysistypes.json").then(response => {
                    this.$store.commit("setOatTypes", response);
                    })

                    
                         
                },            

                loadGoogleClient: function() {
          
                    var apiUrl = 'https://accounts.google.com/gsi/client'
                    return new Promise((resolve) => {
                        var script = document.createElement('script')
                        script.src = apiUrl
                        script.onreadystatechange = script.onload = function () {
                                if (!script.readyState || /loaded|complete/.test(script.readyState)) { setTimeout(function () {resolve()}, 10)}
                        }
                        document.getElementsByTagName('head')[0].appendChild(script)        
                    });
                },

                currency(val) {

                    return new Intl.NumberFormat(navigator.language, { style: 'currency', currency: 'EUR' }).format(val);

                },

                promo: function (val,event,type) {

                    var midofbubble = 220; // popup centres

                    var ypercent = event.clientY / window.innerHeight;
                    var xpercent =  (event.clientX + midofbubble)/ window.innerWidth

                    switch(type) {
                    case 'regular':
                        this.$modal.show('bookmakerpromo', { passedparams: val, mousexpos: xpercent, mouseypos: ypercent}) 
                    break;
                    case 'allpunters':
                        this.$modal.show('allpuntersbookmakerpromo', { passedparams: val, mousexpos: xpercent, mouseypos: ypercent})
                    break;
                    default:
                       //do nothing at moment

                    }

                }, 

                // showTips: function



                siteName: function (val) 
                {
                    
                
                    let sitename = this.$store.state.sitename ;
                    let shortsitename = 'OG'
                
                    if (sitename == 'search4bets')  {shortsitename = 'S4B'} 
                
                
                    val = val.replace("[sitename]", shortsitename)
                    return val;
                },

                tooltipdirection: function (el,windowwidth) {

    
                    const rect =  el.getBoundingClientRect();
                           
                    if  ((rect.right*3)<this.windowWidth) {
                        return false;
                    } else {
                        return true;
                    }
                
                },

                logOut: function () {
                    

                    this.$store.replaceState({currentot: 1,betfaircom: 5,betdaqcom: 2,smarketcom: 2,authtype: '',email: '',username: '',signedin: false,sb:[],nameid: 0,oats: [],selectedoat: 0,adbanners: [],filterbysport: [], regbody: [],favourites: [], users: [], isadmin: false,obfuscatedname: '',bespokebookies: false, sessiontime: '', sports: []})
              
                    this.initialdataload();

                    location.reload();
             
                },

                SVGorPNG: function (path, filename) {
                        
                }


            },


        });
        //global variables

        Vue.prototype.$advertsloaded  = false;

        //enums

        Vue.prototype.$oddstype = Object.freeze({
            decimal: 1,
            fractional: 2,
            american: 3
        })

        Vue.prototype.$bannersize = Object.freeze({
            BackBanColour: 0,
            Ban468x60: 1,
            Ban120x600: 2,
            Ban728x90: 3,
            BanURL: 4,
            Ban320x50: 5,
            Ban300x250: 6

        })
        Vue.prototype.$screensize = Object.freeze({
            small: 0,
            medium: 1,
            large: 2,
            popup: 3

        })

        Vue.prototype.$tabtype = Object.freeze({
            notitle: 0,
            imageonly: 1,
            textonly: 2,
            imageandtext: 3,
            textbelowimage: 4,
            colourstripwwithtest: 5

        })
        
        Vue.prototype.$calendartype = Object.freeze({
            daytoggle: 0,
            fromdate: 1,
            fromandtoodate: 2,
            daytoggleandfromdate: 3

        })
        
        
        //consts



        // global datetime method
        Vue.prototype.$dateformattype = {

            full: { dateStyle: 'full' },
            day: { weekday: 'short' },
            daylong: { weekday: 'long' },
            notime: { weekday: 'short'},
            daytime: { weekday: 'long', hour: '2-digit', minute: '2-digit', hour12: false },
            timeonly: { hour: '2-digit', minute: '2-digit', hour12: false },
            daymonth: { day: 'numeric', month: 'short' },
            daymonthtime: { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false },
            fulldaymonthtime: { weekday: 'long', day: '2-digit', month: 'long', hour: '2-digit', minute: '2-digit', hour12: false },
            daylongmonth: {day: 'numeric', month: 'long'},
            monthyear: {month: 'long', year: 'numeric'},
            yearonly: {year: 'numeric'},
            monthonly: {month: 'long'},
            daymonthshort: { day: 'numeric', month: 'numeric' },
        }


        Vue.prototype.$todayTomorrow= function (displaydate, displayformat) {

            try {
            //function that returns the words today or tomorrow id date applicable
             switch(this.$displayDate(displaydate,displayformat)) {
                 case this.$displayDate(new Date().getDate(),displayformat):
                   return "Today";
                 
                 case this.$displayDate(new Date().getDate()+1,displayformat):
                   return "Tomorrow";
             
                default:
                  return this.$displayDate(displaydate,displayformat);

            }
            } catch(e) {
                console.log(e.message + 'today/tomorrow')
            }

        }

        Vue.prototype.$removeTime= function(date) {
            return new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate()
            );
          }

        Vue.prototype.$displayDate = function (displaydate, displayformat) {

            try {

              
            if (displaydate == undefined)   { displaydate = new Date()}

            var ts = navigator.language;
    
           // console.log(ts)
            
            let d = new Date(displaydate);

            //if the date is 2099 or 1977 its just a place holder so return nothing
            const bumdates = ["1900-01-01T00:00:00", "2009-01-01T00:00:00", "2099-01-01T00:00:00", "1977-01-01T00:00:00"]

            if  (bumdates.includes(displaydate)) { displaydate =''; return; }

            var returnDate;

           if (d != "Invalid Date") {

                var dayno = d.getDate();
                var wr = checker(dayno);
                var monthname = d.toLocaleString(ts, { month: 'short' });
                var monthnumber = d.toLocaleString(ts, { month: 'numeric' });
           
                let tzhours = d.getUTCHours() - Number(this.$store.state.timediff / 60)

                //add in the timediff for current time zone
                d.setHours(tzhours);

        
               // return the date as per the date format object passed into the function
                
             

                // SWAP GB for US as hassle
                //if (ts == 'en-GB') {ts = 'en-US'} 

                //if only time
                switch (displayformat) {
                    
                case this.$dateformattype.daymonthshort:
                    returnDate = dayno + '/' + monthnumber;
                    break;
                case this.$dateformattype.timeonly:
                case this.$dateformattype.daytime: 
                case this.$dateformattype.monthyear:
                case this.$dateformattype.yearonly:
                case this.$dateformattype.daymonthtime:
                        returnDate = d.toLocaleTimeString(ts, displayformat);
                        break;
                case this.$dateformattype.notime:  
                case this.$dateformattype.daylongmonth:
                    monthname = d.toLocaleString(ts, { month: 'long' });
                    returnDate = dayno + wr + ' ' + monthname
                    break;    
           
                case this.$dateformattype.daymonth:
                  
                   returnDate = dayno + wr + ' ' + monthname
                   break;
            
                default:

                   returnDate = (d.toLocaleDateString(ts,displayformat))
                   
                }
       

             } else {
                console.log("Invalid Date - " + displaydate)
                returnDate = ''
             }

            return returnDate;

            } catch(e) {

                console.log(e.message)

            }


        }

        //URL creation

        Vue.prototype.$URLCreateTS = function (urlstub, img1, img2, sec2, prim1, t, id,  compid) {


            // todo - change to switch 
            var URL = '';

            if (urlstub == 'Event') {
                URL = '/' + urlstub + '/' + csTS(img1) + '/' + csTS(img2) + '/' + csTS(sec2) + '/' + csTS(prim1) + '/' + csTS(t) + '/' + id;
            }

            if (urlstub == 'Competition') {
                URL = '/' + urlstub + '/' + compid + '-1-1'
            }

            if (urlstub == 'Outright') {
                URL = '/' + urlstub + '/' + csTS(img1) + '/' + csTS(img2) + '/' + csTS(sec2) + '/' + prim1 + '/' + id;
            }

            if (urlstub == 'Sport') {
                URL = '/' + urlstub + '/' + csTS(img1) + '/All';
            }

            if (urlstub == 'Competitions') {
                URL = '/competition/' + id;
            }

            if (urlstub== undefined || img2==undefined) {
                URL = '.';
            }

            return URL;

        }

        //method for checking a rout before pushing it / create a rerouting of url
        Vue.prototype.$checkBeforeReroute = function (URL) {


            if (URL == '' || URL == '.' || URL == undefined || URL == 'undefined') {
                //do nothing - similar to # or javascript:void(0);
                return
            } else {
                this.$router.push(URL).catch(err => {
                    // Ignore the vuex err regarding  navigating to the page they are already on.
                    if (
                      err.name !== 'NavigationDuplicated' &&
                      !err.message.includes('Avoided redundant navigation to current location')
                    ) {
                      // But print any other errors to the console
                      
                    }
                  });
            }

        }

        // odds format
        Vue.prototype.$formatOddsTS = function (val, bn) {

            var stringFormatted;

            //if the odd = 0 then return blank
            if (val < 1 || val == null) return "";

            //is the bookmaker need commission deducting
            switch (bn) {
                case "Betfair":
                    val = (((100 - parseFloat(this.$store.state.betfaircom)) / 100) * val).toFixed(2);
                    break;
                case "BetDaq":
                    val = (((100 - parseFloat(this.$store.state.betdaqcom)) / 100) * val).toFixed(2);
                    break;
                case "SMarkets":
                    val = (((100 - parseFloat(this.$store.state.smarketcom)) / 100) * val).toFixed(2);
            }

            if (this.$store.state.currentot == this.$oddstype.decimal) return val;

            //Change to select odds type
            stringFormatted = this.$formatOdds(this, val, this.$store.state.currentot);

            return stringFormatted;

        }


        // generate GUID / UUID fro use with Bets. 
        Vue.prototype.$uuidv4 = function () {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }



        // ** helper functions **

        // convert en-us dates to have emphasis after day number
        function checker(x) {
            try {

            if (x > 3 && x < 21) return 'th';
            switch (x % 10) {
                case 1: return "st";
                case 2: return "nd";
                case 3: return "rd";
                default: return "th";
            }
        } catch(e) {
            console.log(e.message)
        }
        }


        ////
        Vue.prototype.$formatOdds = function (_this, val, changetodds) {


            if (changetodds == this.$oddstype.fractional) return OddsConverter.decimal.toFractional(val).simplify();
            if (changetodds == this.$oddstype.american) return OddsConverter.decimal.toAmerican(val);

            return "";

        }

            //take our - and ~ and add space to start and end of value
            Vue.prototype.$spaceandclean = function (val) {

                
               try {

                    val = val.replace("-", " ");
                    val = val.replace("~", " ");
                    val = val.replace("1.", "");
                    val = ' ' + val + ' ';
                
               } catch {

               } 

                return val;

            }

            Vue.prototype.$scrolltoTop = () => window.scrollTo(0,0)




        //replace offensive chars with -
        function csTS(val) {

            return val.replace(/\s+/g, '-');
        }

        //determine what odds type it is by the value
        function whatOddsTypeTS(val) {

            var oddtype;
            val = val.toString();

            oddtype = this.$oddstype.decimal;

            //Is it American
            var amer1 = val.indexOf("+");
            var amer2 = val.indexOf("-");
            if (amer1 != -1 || amer2 != -1) oddtype = this.$oddtype.american;

            //is it fractional
            var frac = val.indexOf("/");
            if (frac != -1) this.$oddType = this.$oddstype.fractional;

            return oddtype

        }


      
    }
             
}

       
