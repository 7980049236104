<template>
    
    <div  v-show='isactive' >
        <slot>
        </slot>
    </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Tab'
    },
    img: {
        type: String,
        default: ""
    },
    colour: {
        type: String,
        default: "#FFFFFF"

    },
    description: {
        type: String,
        default: ""
    },
    requirelogin: {
      type: Boolean,
      default: false
    },
    isactive: {
     type: Boolean,
     default: false
    }

  }
}
</script>

