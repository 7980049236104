<template>
<div class="cell small-10"> 

  <div :class='{"normal": mode === "normal", "other": mode === "other"}'>  

    <ul class='grid-x tabs_header'>  
     
      <li v-for='(tab, index) in tabs' :key='tab.id' @click='selectTab(index)' :class='{"tab_selected": (index == selectedIndex), "tab_unselected": (index != selectedIndex)}' class="cell auto" >
        <template v-if="tab.requirelogin == false || $store.state.signedin == true">
            
            <div v-if="tabtypevar == $tabtype.imageonly" class="qbuttonhover"><img :src="require('/public/assets/' + tab.img)" :alt="tab.title"/></div>
            
            <div v-if="tabtypevar == $tabtype.textonly" style="position: relative;top: 50%;transform: translateY(-50%);">
                 <div  :class="{tooltip: hints}" class="qsmallfontplus qbuttonhover" >
                     {{langConv(tab.title)}}
                     <span class="tooltiptext" :class="[{'tooltip-right': index < (tabs.length / 2)}]" >{{langConv(tab.description)}}</span>
                 </div> 
             </div>
 
            <div v-if="tabtypevar == $tabtype.imageandtext"><img class="hide-for-small-only " :src="require('/public/assets/' + tab.img)" :alt="tab.title"/>{{langConv(tab.title)}}</div>
            
            <div v-if="tabtypevar == $tabtype.textbelowimage" class="qbuttonhover">
          
                    <template v-if="$mq == 'sm'">
                     <div>
                         <img  class="qbordericon" :src="require('/public/assets/' + tab.img + '.png')" :alt="langConv(tab.title)" v-if="index==selectedIndex" />
                         <img  class="qyellowicon" :src="require('/public/assets/' + tab.img + '.png')" :alt="langConv(tab.title)" v-if="index!=selectedIndex" /> 
                     </div>
                      <div class="tooltip qsupersmallfont">
                         <span class="tooltiptext" :class="[{'tooltip-right': index < (tabs.length / 2)}]"> {{langConv(tab.description)}}</span>
                     </div> 
                    </template> 
                
                    <template v-if="$mq != 'sm'">
                     <div>
                         <img  class="qbordericon" :src="require('/public/assets/' + tab.img + '.png')" :alt="langConv(tab.title)" v-if="index==selectedIndex"/>
                         <img  class="qyellowicon" :src="require('/public/assets/' + tab.img + '.png')" :alt="langConv(tab.title)" v-if="index!=selectedIndex"/>
                         <div class="qsupersmallfont">{{langConv(tab.title)}} 
                         <span  class="tooltip" >
                         <span class="tooltiptext" :class="[{'tooltip-right': index < (tabs.length / 2)}]">{{langConv(tab.description)}}</span>
                         </span>
                         </div>  
                     </div> 
                     
                    </template>
                 
               
             </div>
             
             <div v-if="tabtypevar == $tabtype.colourstripwwithtest" class="qbuttonhover">
                 <div style="border-left:4px solid;;height:40px;font-size:small" :style="{'border-left-color': tab.colour}"><img :src="require('/public/assets/' + tab.img )" style="width:26px;height:26px;padding:5px" :alt="langConv(tab.title)" />{{langConv(tab.title)}}</div>
             </div> 
 
         </template>
         <template v-if="tab.requirelogin == true && $store.state.signedin == false">
              <div :class="{tooltip: hints}" class="qsmallfontplus qbuttonhover"  alt="Log in to access this feature"> 
                 <img  class="qrequireloginimg qwhiteicon" style="margin-bottom:2px;margin-left:5px;height:16px;width:16px" :src="require('/public/assets/images/lock-26.png')" />{{langConv(tab.title)}}
                 <span class="tooltiptext" :class="[{'tooltip-right': index < (tabs.length / 2)}]" v-html="langConv(tab.description)"></span> 
             </div>
         </template>
       
      </li>
      
      </ul> 
  
      <slot>
      </slot>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      selectedIndex: 0, // the index of the selected tab,
      tabs: [],         // all of the tabs
      tabtypevar: this.tabtype,
    }
  },
  
  props: {
    mode: {
      type: String,
      default: 'other'
    },
    tabtype: {
        type: Number,
        default: 2
    }
  },

  created () {
   
    this.tabs = this.$children
  
  },
  computed: {
            hints () {
                return this.$store.state.showhints;
            }
        },
  mounted () {
   
    this.selectTab(0)
  },
  methods: {
    
    selectTab (i) 
  
    {
 
      let changed = false;
      if (i != this.selectedIndex) {
        
        // loop over all the tabs
        this.tabs.forEach((tab, index) => {

              if (index === i && (tab.requirelogin == false || this.$store.state.signedin == true)) {
                tab.isActive = true;
                changed = true;
                this.selectedIndex = i
              }
        })
         //emit event back to container of tab - 05082022
        if (changed) {this.$emit("changed", i);}
      }
    }
  }
}
</script>

<style lang="css">

/** CSS for the tabs - TO DO NEED MOVING TO CSS FILE**/

.tabs-component {
    margin: 1em 0;
}

.tabs-component-tabs {
    border: solid 1px #ddd;
    border-radius: 6px;
    margin-bottom: 5px;
}

@media (min-width: 700px) {
    .tabs-component-tabs {
        border: 0;
        align-items: stretch;
        display: flex;
        justify-content: flex-start;
        margin-bottom: -1px;
    }
}

.tabs-component-tab {
    color: #999;
    font-size: 14px;
    font-weight: 600;
    margin-right: 0;
    list-style: none;
}

    .tabs-component-tab:not(:last-child) {
        border-bottom: dotted 1px #ddd;
    }

    .tabs-component-tab:hover {
        color: #666;
    }

    .tabs-component-tab.is-active {
        color: #000;
    }

    .tabs-component-tab.is-disabled * {
        color: #cdcdcd;
        cursor: not-allowed !important;
    }

@media (min-width: 700px) {
    .tabs-component-tab {
        background-color: #fff;
        border: solid 1px #ddd;
        border-radius: 3px 3px 0 0;
        margin-right: .5em;
        transform: translateY(2px);
        transition: transform .3s ease;
    }

        .tabs-component-tab.is-active {
            border-bottom: solid 1px #fff;
            z-index: 2;
            transform: translateY(0);
        }
}

.tabs-component-tab-a {
    align-items: center;
    color: inherit;
    display: flex;
    padding: .75em 1em;
    text-decoration: none;
}

.tabs-component-panels {
    padding: 1em 0;
}

@media (min-width: 700px) {
    .tabs-component-panels {
        border-top-left-radius: 0;
        background-color: #fff;
        border: solid 1px #ddd;
        border-radius: 0 6px 6px 6px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .05);
        padding: 1em 2em;
    }
}

    ul.tabs_header > li.tab_unselected {
      margin: 4px;
      border-radius: 3px;
      color:white;
  }

  .normal .tab{
      background-color: #fff;
      border-color  : grey;
  }

  .normal li {
  
    color: black;

    font-size: small;
  }

  /* .normal li.tab_selected {
    background-color: white;
    color:  black;
    
  }  */

  /* .other .tab{
    background-color: ;
    color: #eee;
  } */

  .other li {
    background-color:#0d0638; 
    color: #aaa;
  }

  /* .other li.tab__selected {
    background-color: #555;
    color: white;

  } */
  .blank  {
    border:none !important;
    background-color:white !important;
  }




</style>