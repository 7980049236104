<!-- Vue component -->
<template>
  <div v-if="loaded" >
    <multiselect 
      :showLabels="false"
      :multiple="multiselect"
      :allow-empty="false"
      :searchable="false"
      :disabled="ocdisabled"
      label="D"
      :options="options"
      :close-on-select="closeonselect"
      @input="onChange"
      @close="onClose"
      v-model="v"
      :placeholder="placeholder"
      track-by="D"
      :show-list-on-start="showlistonstart"
      :max-height="heightoflist"
      openDirection="below"
    >
      <template slot="selection">
        <span class="multiselect__single" v-if="v.length > 0"
          >{{ v.length }} {{langConv('options selected')}}</span
        >
        <!-- <span> Select from List Below </span> -->
        
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "../modified3rdpartycomponents/Multiselect.vue";
import axios from "axios";
export default {
  name: "dropdown",
  components: { Multiselect },
  data() {
    return {
      v: [],
      options: [],
      placeholder: '',
      multiselect: this.ocmultiselect,
      closeonselect: this.occloseonselect,
      showlistonstart: this.ocshowlistonstart,
      heightoflist: this.ocheightoflist,
      loaded: false,
      processing: false
    };
  },
  mounted() {
    this.placeholder = this.langConv(this.ocdefaultvalue);
    this.dropdownData();
  },

  props: {
    ocname: String,
    ocid: [Number, String],
    ocnoitems: Number,
    ocdefaultvalue: String,
    oclocaldata: { type: String, default: 'webapi'},
    ocmultiselect: { type: Boolean, default: false },
    occloseonselect: { type: Boolean, default: true },
    ocdefaultselectedoptions: String,
    ocoptions: Array,
    ocshowlistonstart: { type: Boolean, default: false },
    ocheightoflist: { type: Number, default: 200 },
    ocdisabled: {type: Boolean, default: false}
  },

  methods: {
    //get data for banners
    dropdownData: function () {

        

      let URL = "";

      //has the data been passed rather than get from file for SQL
      if (typeof this.ocoptions !== "undefined") {
        if (this.ocoptions.length > 0) {
          this.options = this.ocoptions;
          this.setDefaultDropDownOptions(this);
          this.loaded = true;
        }
      }

      if (typeof this.ocoptions == "undefined") {
        //Same population values but different return type
        let serviceName = this.ocname;
        
        switch (this.oclocaldata ) {
              
              //retrieve from web api
              case "webapi":

                //has the last request been processed. if not then return
                if (this.processing == false) {this.processing = true} else {return}

                URL = process.env.VUE_APP_WEBAPI + "FeatureTables.svc/" + serviceName;

                axios.get(URL, {
                  params: {
                    id: parseInt(this.ocid), //parseInt(this.id), //can get multiple banner sizes by passing the banner number into the array
                    noitems: parseInt(this.ocnoitems), //parseInt(this.noitems), // does nothing at the moment 18/01/2019
                    desc: "%%",
                    minoddscount: 0, //there does not have to be any odds in though this may change 21/09/2022
                    selectedoptions: '[]', //return all bookmakers
                    country: this.$store.state.countryname,
                  },
                })
                .then((response) => {
                  this.options = JSON.parse(response.data);
                  this.setDefaultDropDownOptions(this)
                  this.loaded = true;
                  this.processing = false;
                },
                (error) => {
                  console.log(error);
                }
              );
              break;

              //populate with physical file
              case "file":
                
                this.localJSONLoad(process.env.VUE_APP_DATA + serviceName + ".json").then(response => {
                    this.options = response
                    this.setDefaultDropDownOptions(this);
                  this.loaded=true;
               });


                
                  
                  break;

               //oclocal is the actual dataitself
               default:
                  this.options = JSON.parse(this.oclocaldata);
                  this.setDefaultDropDownOptions(this);
                  this.loaded=true;

        }     
      }


    },

    setDefaultDropDownOptions(self) {

      if (
        self.ocdefaultselectedoptions != null &&
        self.ocdefaultselectedoptions != "null" &&
        self.ocdefaultselectedoptions != undefined &&
        self.ocdefaultselectedoptions != "undefined"
      ) {
        //13-05 if 'All' is passed then default values
        if (self.ocdefaultselectedoptions == 'All') {

          this.v= self.options;

        } else {

          this.v = JSON.parse(self.ocdefaultselectedoptions);

        }
      

      //send hook back of selected values to specific calling components - searchcriteria.vue
      switch (this.ocname) {

        case "getusers":
          self.$emit("users_values",  self.v);
          break;

        case "retrievebetstrategies":
          self.$emit("strategy_values", self.v);
          break;


        default:
          // open new window with URL
          // window.location.replace(options.Value, "_self");
    

      }
      }
    },


    onClose(options) {
      switch (this.ocname) {
        case "selectsports":
          this.$emit("sport_values", options);
          break;
      }
    },

    onChange(options) {
      this.placeholder = options.D;

    

      // depending on what sort of drop down determines action when value is clicked
      switch (this.ocname) {
        case "timezones":
          this.$emit("timezone_values", options.v, options.D);
          break;

        case "selectbookmaker":
          this.$emit("bookmaker_values", options);
          break;

         case "selectsports":
             this.$emit('sport_values', options);
             break;

        case "outrightsort":
          this.$emit("sort_value", options.v);
          break;

        case "getusers":
          this.$emit("users_values", options);
          break;

        case "retrievebetstrategies":
          this.$emit("strategy_values", options);
          break;

        // case "retrievebetstrategy":
        //   this.$emit("strategy_value", options.v);
        //   break;

        case "OtherTPOBetTypes":
          this.$emit("change_bettype", options.D);
          break;

        default:
          // open new window with URL
          // window.location.replace(options.Value, "_self");
          break;
      }
    },
  },
};
</script>


<style>
</style>