<template>
    <div>
        <!-- List of User Favourite teams -->
        <template>
            <span v-for="(row,i) in $store.state.favourites" :key="i">
               {{row.Description}} <star  :cmpid="row.competitorid" :cmpdesc="row.Description"></star>

            </span>

        </template>

    </div>



</template>

<script>
    

 import star from '/src/components/components/star.vue'

export default {

  data: function () {
    return {

    };
  },
  components: {
    star
  },

  mounted() {
  
  },


}
</script>

<style>

</style>